import { useContext, FC, useEffect, useState } from 'react';
import { CSVLink } from 'react-csv';
import { ReferenceDataContext } from '../../context/ReferenceDataContext';
import { User, UserSkill } from '../../core';
import { capitalizer } from '../../utils/helpers/capitalizer';
import Button from '../Button/Button';
import { getReferenceDataByValue, RefDataType } from '../../utils/helpers/referenceData';

interface Props {
  usersData: User[];
}

export const Download: FC<Props> = ({ usersData }) => {
  const { skillsData, referenceData } = useContext(ReferenceDataContext);
  const [userData, setUserData] = useState<[]>([]);
  const [headers, setHeaders] = useState<{ label: string; key: string }[]>([
    { label: 'Name', key: 'name' },
    { label: 'Email', key: 'email' },
    { label: 'Department', key: 'department' },
    { label: 'Role', key: 'role' },
  ]);
  const dateNow = new Date().toLocaleString().replace(', ', '-');

  useEffect(() => {
    if (usersData && skillsData) {
      (() => {
        const arr = headers;
        skillsData.forEach((skill) => {
          if (!arr.some((item) => item.label === skill.label)) {
            arr.push({ label: skill.label, key: skill.label });
            setHeaders(arr);
          }
        });
      })();

      const newUsers: [] = [];
      usersData.forEach((user: User) => {
        const newUser = {
          department: user.department
            ? getReferenceDataByValue(user.department, RefDataType.Department, referenceData!).label
            : '-',
          role: user.role ? getReferenceDataByValue(user.role, RefDataType.Role, referenceData!).label : '-',
          name: user.name,
          email: user.email!,
        };

        skillsData.forEach((skill) => {
          let level = null;
          user.userSkills.find((userSkill: UserSkill) => {
            if (userSkill.skill === skill.value) {
              level = getReferenceDataByValue(userSkill.skillLevel, RefDataType.SkillLevel, referenceData!).label;
            }
          });
          if (level) {
            //@ts-ignore
            newUser[skill.label] = capitalizer(level);
          } else {
            //@ts-ignore
            newUser[skill.label] = 'N/A';
          }
        });
        //@ts-ignore
        newUsers.push(newUser);
      });

      setUserData(newUsers);
    }
  }, []);

  return (
    <div className="mb-10">
      <CSVLink data={userData} headers={headers} filename={`ApadmiSkillsAllDataExport${dateNow}`} target="_blank">
        <Button btnType="success wide">Export All Data</Button>
      </CSVLink>
    </div>
  );
};
