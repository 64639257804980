import { FC, useState, createContext, useEffect, useContext } from 'react';
import { useQuery } from 'react-query';
import {
  Department,
  Role,
  Skill,
  SkillLevel,
  ReferenceDataApi,
  Configuration,
  ReferenceData,
  SkillGroup,
  CertificationProvider,
  Certificate,
} from '../core';
import { AuthContext } from './AuthContext';

interface Props {
  children: React.ReactNode;
}

interface ReferenceDataInterface {
  skillLevelsData: SkillLevel[];
  departmentsData: Department[];
  rolesData: Role[];
  skillsData: Skill[];
  skillGroupsData: SkillGroup[];
  referenceDataLoaded: boolean;
  referenceData: ReferenceData | undefined;
  certificateData: Certificate[];
  certificationProviderData: CertificationProvider[];
  setReferenceData: (referenceData: ReferenceData) => void;
}

type ReferenceDataResponse = {
  Certificates: Certificate[];
  CertificationProviders: CertificationProvider[];
  Departments: Department[];
  Roles: Role[];
  SkillGroups: SkillGroup[];
  SkillLevels: SkillLevel[];
  Skills: Skill[];
};

export const ReferenceDataContext = createContext<ReferenceDataInterface>({} as ReferenceDataInterface);

const ReferenceDataContextProvider: FC<Props> = ({ children }) => {
  const { user, checkUser } = useContext(AuthContext);
  const api = new ReferenceDataApi(new Configuration({ apiKey: user && user.idToken.jwtToken }));

  const [skillLevelsData, setSkillLevelsData] = useState<SkillLevel[]>([]);
  const [skillsData, setSkillsData] = useState<Skill[]>([]);
  const [skillGroupsData, setSkillGroupsData] = useState<SkillGroup[]>([]);
  const [departmentsData, setDepartmentsData] = useState<Department[]>([]);
  const [rolesData, setRolesData] = useState<Role[]>([]);
  const [certificateData, setCertificateData] = useState<Certificate[]>([]);
  const [certificationProviderData, setCertificationProviderData] = useState<CertificationProvider[]>([]);
  const [referenceDataLoaded, setReferenceDataLoaded] = useState<boolean>(false);
  const [referenceData, setReferenceData] = useState<ReferenceData>({} as ReferenceData);

  const getReferenceDataApi = () => {
    checkUser();
    if (user) {
      return api.getReferenceData();
    }
  };

  const { data, refetch } = useQuery('referenceData', getReferenceDataApi, {
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    refetch();
  }, [user]);

  useEffect(() => {
    const sortData = (data: ReferenceDataResponse) => {
      sortAlphabetically(data.SkillGroups);
      sortAlphabetically(data.Skills);
      sortAlphabetically(data.Roles);
      sortAlphabetically(data.Departments);
      sortAlphabetically(data.CertificationProviders);
      sortCertificates(data.Certificates, data.CertificationProviders);
    };

    if (data) {
      //@ts-ignore
      setReferenceData(data.data);
      sortData(data.data);
    }
  }, [data, referenceData]);

  useEffect(() => {
    if (user && referenceData) {
      setSkillsData(referenceData.Skills);
      setRolesData(referenceData.Roles);
      setDepartmentsData(referenceData.Departments);
      setSkillLevelsData(referenceData.SkillLevels);
      setSkillGroupsData(referenceData.SkillGroups);
      setCertificationProviderData(referenceData.CertificationProviders);
      setCertificateData(referenceData.Certificates);

      setReferenceDataLoaded(true);
    }
  }, [user, referenceData]);

  const sortAlphabetically = (input: Skill[] | Role[] | Department[]) => {
    if (input) {
      return input.sort((a, b) => {
        if (a.label.toLowerCase() < b.label.toLowerCase()) return -1;
        if (a.label.toLowerCase() > b.label.toLowerCase()) return 1;
        return 0;
      });
    }
  };

  const sortCertificates = (certificates: Certificate[], certificateProviders: CertificationProvider[]) => {
    if (certificates) {
      return certificates.sort((a, b) => {
        const providerA = certificateProviders.find((provider) => a.certificateProvider === provider.value)!.label;
        const providerB = certificateProviders.find((provider) => b.certificateProvider === provider.value)!.label;

        // sort certificates by provider, then by name
        return providerA.localeCompare(providerB) || a.label.localeCompare(b.label);
      });
    }
  };

  return (
    <ReferenceDataContext.Provider
      value={{
        skillLevelsData,
        skillsData,
        departmentsData,
        rolesData,
        referenceDataLoaded,
        referenceData,
        skillGroupsData,
        setReferenceData,
        certificateData,
        certificationProviderData,
      }}
    >
      {children}
    </ReferenceDataContext.Provider>
  );
};

export default ReferenceDataContextProvider;
