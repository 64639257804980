import './Admin.scss';
import { useState, useEffect, FC, useContext } from 'react';
import { Download } from '../../components/Download/Download';
import { ReferenceDataContext } from '../../context/ReferenceDataContext';
import { Department, Role, Skill, SkillGroup, User } from '../../core';
import Select from 'react-select';
import Button from '../../components/Button/Button';
import UsersTable from '../../components/UsersTable/UsersTable';
import Loader from '../../components/Loader/Loader';
import { AdminContext } from '../../context/AdminContext';
import { getReferenceDataByValue, RefDataType } from '../../utils/helpers/referenceData';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import SkillsDistributionBarChart from '../../components/Charts/SkillsDistributionBarChart/SkillsDistributionBarChart';
import * as nonDeveloperRole from '../../constants/nonDevRole';

const Admin: FC = () => {
  const { allUsers, isLoading } = useContext(AdminContext);
  const { skillsData, departmentsData, rolesData, skillGroupsData, referenceData } = useContext(ReferenceDataContext);

  const allObject = { value: '1', label: 'All' };
  const [filteredUsers, setFilteredUsers] = useState<User[]>();
  const [name, setName] = useState<string>('');
  const [selectedSkills, setSelectedSkills] = useState<Skill[]>([]);
  const [selectedSkillGroups, setSelectedSkillGroups] = useState<SkillGroup[]>([]);
  const [selectedDepartment, setSelectedDepartment] = useState<Department | null>(allObject);
  const [selectedRole, setSelectedRole] = useState<Role | null>(allObject);

  useEffect(() => {
    let tempUsers = allUsers;

    const skillLabels = selectedSkills.map((item) => {
      return item.label.toLowerCase();
    });

    const skillGroupLabels = selectedSkillGroups.map((item) => {
      return item.label.toLowerCase();
    });

    tempUsers = tempUsers.filter((user: User) => {
      if (selectedDepartment && selectedDepartment.value === allObject.value) {
        return user;
      }
      return user.department === selectedDepartment?.value;
    });

    tempUsers = tempUsers.filter((user: User) => {
      if (selectedRole && selectedRole.value === allObject.value) {
        return user;
      }
      return user.role === selectedRole?.value;
    });

    tempUsers = tempUsers.filter((user: User) => {
      if (name === '') {
        return user;
      }

      return user.name.toUpperCase().includes(name.toUpperCase());
    });

    tempUsers = tempUsers.filter((user: User) => {
      if (skillLabels.length === 0) {
        return user;
      } else {
        let includesSkill = true;

        selectedSkills.forEach((selectedSkill) => {
          if (user.userSkills.filter((userSkill) => userSkill.skill === selectedSkill.value).length === 0) {
            includesSkill = false;
          }
        });

        if (includesSkill) {
          return user;
        } else {
          return null;
        }
      }
    });

    tempUsers = tempUsers.filter((user: User) => {
      if (skillGroupLabels.length === 0) {
        return user;
      } else {
        let includesSkillGroup = true;

        selectedSkillGroups.forEach((selectedSkillGroup) => {
          let count = 0;
          user.userSkills &&
            user.userSkills.forEach((userSkill) => {
              const skill = getReferenceDataByValue(userSkill.skill, RefDataType.Skill, referenceData!) as Skill;
              if (skill.skillGroup === selectedSkillGroup.value) {
                count++;
              }
            });
          if (count === 0) {
            includesSkillGroup = false;
          }
        });

        if (includesSkillGroup) {
          return user;
        } else {
          return null;
        }
      }
    });

    tempUsers = tempUsers.filter((user: User) => user.role !== nonDeveloperRole.NON_DEVELOPER_ROLE_ID);

    setFilteredUsers(tempUsers);
  }, [
    name,
    selectedSkills,
    selectedSkillGroups,
    selectedRole,
    selectedDepartment,
    allObject.value,
    allUsers,
    referenceData,
  ]);

  const resetFilters = () => {
    setName('');
    setSelectedSkills([]);
    setSelectedSkillGroups([]);
    setSelectedDepartment(allObject);
    setSelectedRole(allObject);
  };

  return (
    <section className="wrapper admin-container">
      <div className="page-heading">
        <h2>Admin Dashboard</h2>
      </div>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <div className="button-container">
            <Button btnType="success" clicked={resetFilters}>
              Reset
            </Button>
          </div>
          <div className="filter-container">
            <div className="filter-inputs">
              <div className="row">
                <div className="input-group">
                  <label htmlFor="name">Name</label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    placeholder="Name"
                    value={name}
                    maxLength={26}
                    onChange={(e) => {
                      setName(e.target.value);
                    }}
                  />
                </div>
                <div className="filter-dropdown">
                  <label htmlFor="skills">Skills</label>
                  <Select
                    className="basic-multi-select"
                    classNamePrefix="select"
                    isSearchable={true}
                    isMulti
                    name="skills"
                    id="skills"
                    value={selectedSkills}
                    options={skillsData}
                    onChange={(e) => {
                      setSelectedSkills(e as Skill[]);
                    }}
                  />
                </div>
                <div className="filter-dropdown">
                  <label htmlFor="skills">Skill Groups</label>
                  <Select
                    className="basic-multi-select"
                    classNamePrefix="select"
                    isSearchable={true}
                    isMulti
                    name="skillGroups"
                    id="skillGroups"
                    value={selectedSkillGroups}
                    options={skillGroupsData}
                    onChange={(e) => {
                      setSelectedSkillGroups(e as SkillGroup[]);
                    }}
                  />
                </div>
                <div className="filter-dropdown">
                  <label htmlFor="department">Department</label>{' '}
                  <Select
                    className="basic-single"
                    classNamePrefix="select"
                    isSearchable={true}
                    defaultValue={allObject}
                    name="color"
                    id="department"
                    value={selectedDepartment}
                    options={[allObject].concat(departmentsData)}
                    onChange={(e) => {
                      setSelectedDepartment(e);
                    }}
                  />
                </div>
                <div className="filter-dropdown">
                  <label htmlFor="role">Role</label>{' '}
                  <Select
                    className="basic-single sort"
                    classNamePrefix="select"
                    isSearchable={true}
                    defaultValue={allObject}
                    name="role"
                    id="role"
                    value={selectedRole}
                    options={[allObject]
                      .concat(rolesData)
                      .filter(
                        (role: Role) => role && role.label && role.label !== nonDeveloperRole.NON_DEVELOPER_ROLE_NAME
                      )}
                    onChange={(e) => {
                      setSelectedRole(e);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="data-container">
            <Tabs>
              <TabList>
                <Tab>Employee Table</Tab>
                <Tab>Skills Chart</Tab>
              </TabList>
              <TabPanel className="user-list-tab">
                <div className="user-list-container w-full">
                  <UsersTable filteredUsers={filteredUsers} setFilteredUsers={() => setFilteredUsers} />
                  <Download usersData={allUsers} />
                </div>
              </TabPanel>
              <TabPanel className="no-spacing">
                <SkillsDistributionBarChart listOfUsers={filteredUsers || []} />
              </TabPanel>
            </Tabs>
          </div>
        </>
      )}
    </section>
  );
};

export default Admin;
