import { Department, Role, Skill, SkillLevel, ReferenceData, CertificationProvider } from './../../core/api';

export enum RefDataType {
  Skill = 'Skills',
  SkillLevel = 'SkillLevels',
  Department = 'Departments',
  Role = 'Roles',
  SkillGroup = 'SkillGroups',
  CertificationProvider = 'CertificationProviders',
}

export const getReferenceDataByValue = (
  valueToFind: string | number,
  type: RefDataType,
  referenceData: ReferenceData
): Department | Role | Skill | SkillLevel | CertificationProvider => {
  let result;

  if (referenceData) {
    const referenceArray = referenceData[type] as Array<Department | Role | SkillLevel | Skill | CertificationProvider>;

    if (referenceArray) {
      result = referenceArray.find((obj) => {
        return obj.value === valueToFind;
      });
    }
  }
  return result || { value: '-', label: '-' };
};
