import { FC, useState, createContext, useEffect, useContext } from 'react';
import { User, UsersApi, Configuration, UserApi } from '../core';
import { AuthContext } from './AuthContext';
import { Auth } from 'aws-amplify';
import { toast } from 'react-toastify';
import { useQuery } from 'react-query';

interface Props {
  children: React.ReactNode;
}

interface AdminContextInterface {
  allUsers: User[];
  isLoading: boolean;
  postUser: (user: User) => void;
  deleteUser: (email: string | undefined) => void;
}

export const AdminContext = createContext<AdminContextInterface>({} as AdminContextInterface);

const AdminContextProvider: FC<Props> = ({ children }) => {
  const { user, isUserAdmin, isUserAGroupManager, isUserSuperAdmin } = useContext(AuthContext);
  const usersApi = new UsersApi(new Configuration({ apiKey: user && user.idToken.jwtToken }));
  const userApi = new UserApi(new Configuration({ apiKey: user && user.idToken.jwtToken }));

  const [allUsers, setAllUsers] = useState<User[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const getUsersApi = () => {
    Auth.currentSession();
    if (isUserAdmin() || isUserSuperAdmin()) {
      setIsLoading(true);
      return usersApi.getAllUsers();
    }
  };

  const { data, isError, refetch } = useQuery('usersData', getUsersApi, {
    refetchOnWindowFocus: false,
    useErrorBoundary: true,
  });

  useEffect(() => {
    refetch();
  }, [isUserAdmin, isUserSuperAdmin]);

  useEffect(() => {
    if (data && data.status === 200) {
      setAllUsers(data.data);
      setIsLoading(false);
    } else if (isError) {
      setIsLoading(false);
      console.log(isError);
    }
  }, [data, isError]);

  const postUser = (userRequest: User) => {
    (async () => {
      setIsLoading(true);
      try {
        if (user) {
          Auth.currentSession();
        }
        await userApi.adminUpdateUser(userRequest);

        if (userRequest.id) {
          const userList = allUsers.filter((user) => user.id !== userRequest.id);
          userList.push(userRequest);
          setAllUsers(userList);
        } else {
          allUsers.push(userRequest);
        }
        setIsLoading(false);

        (() =>
          toast.success('Saved!', {
            position: 'top-right',
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }))();
      } catch (error) {
        console.log(error);
        setIsLoading(false);
      }
    })();
  };

  const deleteUser = (email: string | undefined) => {
    setIsLoading(true);
    (async () => {
      try {
        if (email && (isUserSuperAdmin() || isUserAGroupManager())) {
          if (user) {
            Auth.currentSession();
          }
          await userApi.deleteUser(email);
          setAllUsers(allUsers.filter((user) => user.email !== email));

          setIsLoading(false);
        }
      } catch (error) {
        console.log(error);
        setIsLoading(false);
      }
    })();
  };

  return (
    <AdminContext.Provider
      value={{
        allUsers,
        isLoading,
        postUser,
        deleteUser,
      }}
    >
      {children}
    </AdminContext.Provider>
  );
};

export default AdminContextProvider;
