import { useContext, FC } from 'react';
import { UserContext } from '../../context/UserContext';
import { AuthContext } from '../../context/AuthContext';
import NavItem from './NavItem/NavItem';
import './Header.scss';
import { ReferenceDataContext } from '../../context/ReferenceDataContext';
import { getReferenceDataByValue, RefDataType } from '../../utils/helpers/referenceData';

const Header: FC = () => {
  const { referenceData, referenceDataLoaded } = useContext(ReferenceDataContext);
  const { userData } = useContext(UserContext);
  const { user, username, signOut, signIn, isUserAdmin, isUserReferenceDataManager, isUserAGroupManager } =
    useContext(AuthContext);

  return (
    <header>
      <nav className="flex flex-row items-center justify-center px-6 py-8 2xl:px-0 max-w-[1280px] mx-auto">
        <h3 className="hidden flex-row 2xl:flex 2xl:w-[35%]">
          {user ? username : 'Apadmi Skills'}{' '}
          {referenceDataLoaded &&
            userData.department &&
            user &&
            getReferenceDataByValue(userData.department, RefDataType.Department, referenceData!).label}
        </h3>
        <ul className="flex flex-row items-center w-full 2xl:w-[65%] gap-8 justify-end">
          {user ? (
            <>
              <NavItem link="/">Skills</NavItem>
              {isUserAdmin() && <NavItem link="/admin">Admin</NavItem>}
              {isUserAGroupManager() && <NavItem link="/users">Users</NavItem>}
              {isUserReferenceDataManager() && <NavItem link="/referenceData">Reference Data</NavItem>}
              <li className="nav-item">
                <button
                  onClick={signOut}
                  className="flex flex-row justify-between items-center bg-[white] p-2 rounded-2xl"
                >
                  <img src="icons/google.svg" alt="google login" className="h-6 w-6 mr-2" />

                  <span className="text-[#4285f4]">Sign out</span>
                </button>
              </li>
            </>
          ) : (
            <>
              <NavItem link="/">Home</NavItem>
              <li className="nav-item">
                <button
                  onClick={signIn}
                  className="flex flex-row justify-between items-center bg-[white] p-2 rounded-2xl ml-6"
                >
                  <img src="icons/google.svg" alt="google login" className="h-6 w-6 mr-2" />

                  <span className="text-[#4285f4]">Sign in</span>
                </button>
              </li>
            </>
          )}
        </ul>
      </nav>
    </header>
  );
};

export default Header;
