import './../ListOfSkills/ListOfSkills.scss';
import { FC, useState, useEffect, useContext } from 'react';
import Select from 'react-select';
import { ReferenceDataContext } from '../../context/ReferenceDataContext';
import { Department, Role, Certificate } from '../../core';
import Button from '../Button/Button';
import { getReferenceDataByValue, RefDataType } from '../../utils/helpers/referenceData';
import { toast } from 'react-toastify';

interface Props {
  userDepartment: Department | undefined;
  userRole: Role | undefined;
  certifications: Certificate[] | undefined;
  addUserCertification: (certificate: Certificate) => void;
  removeUserCertification: (certificate: Certificate) => void;
}

const ListOfCerts: FC<Props> = ({
  userDepartment,
  userRole,
  certifications,
  addUserCertification,
  removeUserCertification,
}) => {
  const [cert, setCert] = useState<Certificate | null>();
  const [reducedCerts, setReducedCerts] = useState<Certificate[]>();

  const { certificateData, referenceData } = useContext(ReferenceDataContext);

  function enableSubmit(): boolean {
    return !!cert && !!userRole && !!userDepartment && userRole?.value !== '-' && userDepartment?.value !== '-';
  }

  useEffect(() => {
    const reducedArray: Certificate[] = [];

    if (certificateData) {
      certificateData.forEach((cert) => {
        let hasCert = false;
        certifications &&
          certifications.forEach((userCert) => {
            if (cert.value === userCert.value) {
              hasCert = true;
            }
          });
        if (!hasCert) {
          const updatedCert = {
            label: `${
              getReferenceDataByValue(cert.certificateProvider!, RefDataType.CertificationProvider, referenceData!)
                .label
            } - ${cert.label}`,
            value: cert.value,
            certificateProvider: cert.certificateProvider,
          };
          reducedArray.push(updatedCert);
        }
      });

      setReducedCerts(reducedArray);
    }
  }, [certificateData, certifications]);

  const onSubmitCerts = () => {
    if (!cert) {
      (() =>
        toast.error('Failed...', {
          position: 'top-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }))();

      return;
    }

    addUserCertification({ label: cert.label, value: cert.value, certificateProvider: cert.certificateProvider });

    setCert(null);
  };

  const removeCert = (cert: Certificate) => {
    removeUserCertification(cert);
  };

  return (
    <>
      {userDepartment && userRole && Object.keys(userDepartment).length && Object.keys(userRole).length > 0 ? (
        <>
          <form className="skills-form flex justify-between gap-6">
            <div className="flex flex-col w-full">
              <label htmlFor="cert">Certification</label>
              <Select
                className="basic-single"
                classNamePrefix="select"
                id="certs"
                isSearchable={true}
                name="color"
                options={reducedCerts}
                value={cert}
                onChange={(e) => {
                  setCert(e);
                }}
              />
            </div>
            <Button enabled={enableSubmit()} btnType="success" clicked={onSubmitCerts}>
              <i className="fa fa-plus" aria-hidden="true"></i>
            </Button>
          </form>
          {certifications && certifications.length !== 0 ? (
            <div className="skills-wrapper">
              <div className="skills">
                <table className="skills-table">
                  {certifications.length > 0 && (
                    <thead>
                      <tr>
                        <th>Cert Name</th>
                        <th></th>
                      </tr>
                    </thead>
                  )}
                  <tbody>
                    {certifications &&
                      certifications.map((certificate: Certificate, index) => {
                        return (
                          <tr key={index}>
                            <td>{certificate.label}</td>
                            <td>
                              <button className="skills-btn delete-btn" onClick={() => removeCert(certificate)}>
                                <i className="fas fa-trash-alt"></i>
                              </button>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </div>
          ) : (
            <></>
          )}
        </>
      ) : (
        <p className="warning">Please complete the Employee Information section</p>
      )}
    </>
  );
};

export default ListOfCerts;
