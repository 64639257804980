import './ReferenceData.scss';
import { useContext, useState, FC, useEffect } from 'react';
import Button from '../../components/Button/Button';
import { ReferenceDataContext } from '../../context/ReferenceDataContext';
import 'react-accessible-accordion/dist/fancy-example.css';
import {
  Certificate,
  CertificationProvider,
  Configuration,
  Department,
  ReferenceData as ReferenceDataModel,
  ReferenceDataApi,
  Role,
  Skill,
  SkillGroup,
} from '../../core';
import { AuthContext } from '../../context/AuthContext';
import { Auth } from 'aws-amplify';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import LabelValueSection from './LabelValueSection/LabelValueSection';
import Loader from '../../components/Loader/Loader';

const ReferenceData: FC = () => {
  const { user } = useContext(AuthContext);
  const api = new ReferenceDataApi(new Configuration({ apiKey: user && user.idToken.jwtToken }));
  const {
    skillsData,
    rolesData,
    departmentsData,
    skillLevelsData,
    certificateData,
    certificationProviderData,
    referenceDataLoaded,
    skillGroupsData,
    setReferenceData,
  } = useContext(ReferenceDataContext);

  const [skillsDataList, setSkillsDataList] = useState<Skill[]>(skillsData);
  const [skillGroupsDataList, setSkillGroupsDataList] = useState<SkillGroup[]>(skillGroupsData);
  const [departmentsDataList, setDepartmentsDataList] = useState<Department[]>(departmentsData);
  const [rolesDataList, setRolesDataList] = useState<Role[]>(rolesData);
  const [certificateDataList, setCertificateDataList] = useState<Certificate[]>(certificateData);
  const [certificationProviderDataList, setCertificationProviderDataList] =
    useState<CertificationProvider[]>(certificationProviderData);
  const [hasChanged, setHasChanged] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (
      referenceDataLoaded &&
      (JSON.stringify(departmentsDataList) !== JSON.stringify(departmentsData) ||
        JSON.stringify(rolesDataList) !== JSON.stringify(rolesData) ||
        JSON.stringify(skillsDataList) !== JSON.stringify(skillsData) ||
        JSON.stringify(skillGroupsDataList) !== JSON.stringify(skillGroupsData) ||
        JSON.stringify(certificationProviderDataList) !== JSON.stringify(certificationProviderData) ||
        JSON.stringify(certificateDataList) !== JSON.stringify(certificateData))
    ) {
      setHasChanged(true);
    } else {
      setHasChanged(false);
    }
  }, [
    skillsDataList,
    rolesDataList,
    departmentsDataList,
    skillGroupsDataList,
    certificateDataList,
    certificationProviderDataList,
    referenceDataLoaded,
    departmentsData,
    rolesData,
    skillsData,
    skillGroupsData,
    certificateData,
    certificationProviderData,
  ]);

  useEffect(() => {
    setSkillsDataList(skillsData);
    setSkillGroupsDataList(skillGroupsData);
    setDepartmentsDataList(departmentsData);
    setRolesDataList(rolesData);
    setCertificateDataList(certificateData);
    setCertificationProviderDataList(certificationProviderData);
  }, [skillsData, skillGroupsData, departmentsData, rolesData, certificateData, certificationProviderData]);

  const submitChanges = async () => {
    const newReferenceData = {
      Skills: skillsDataList,
      Roles: rolesDataList,
      SkillLevels: skillLevelsData,
      Departments: departmentsDataList,
      SkillGroups: skillGroupsDataList,
      Certificates: certificateDataList,
      CertificationProviders: certificationProviderDataList,
    };

    updateReferenceData(newReferenceData);
  };

  const updateReferenceData = async (newReferenceData: ReferenceDataModel) => {
    try {
      if (user) {
        Auth.currentSession();
      }
      setHasChanged(false);
      setIsLoading(true);
      const res = await api.updateReferenceData(newReferenceData);

      if (res.status === 200) {
        setReferenceData(newReferenceData);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  return (
    <section className="wrapper ref-data-container">
      <div className="page-heading">
        <h2>Reference Data</h2>
        <p>Make sure to submit all changes when you&#39;re finished editing</p>
        <div className="submit-button-container">
          <Button enabled={hasChanged} btnType="success" clicked={submitChanges}>
            Submit Changes
          </Button>
        </div>
      </div>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="reference-container">
          <Tabs>
            <TabList>
              <Tab>Employee Data</Tab>
              <Tab>Skills Data</Tab>
              <Tab>Certification Data</Tab>
            </TabList>
            <TabPanel>
              <LabelValueSection
                name={'Department'}
                labelValuesDataList={departmentsDataList}
                setLabelValuesDataList={setDepartmentsDataList}
                setHasChanged={setHasChanged}
              />
              <LabelValueSection
                name={'Role'}
                labelValuesDataList={rolesDataList}
                setLabelValuesDataList={setRolesDataList}
                setHasChanged={setHasChanged}
              />
            </TabPanel>
            <TabPanel>
              <LabelValueSection
                name={'Skill Group'}
                labelValuesDataList={skillGroupsDataList}
                setLabelValuesDataList={setSkillGroupsDataList}
                setHasChanged={setHasChanged}
              />
              <LabelValueSection
                name={'Skill'}
                skillGroupsDataList={skillGroupsDataList}
                labelValuesDataList={skillsDataList}
                setLabelValuesDataList={setSkillsDataList}
                setHasChanged={setHasChanged}
              />
            </TabPanel>
            <TabPanel>
              <LabelValueSection
                name={'Certification Provider'}
                labelValuesDataList={certificationProviderDataList}
                setLabelValuesDataList={setCertificationProviderDataList}
                setHasChanged={setHasChanged}
              />
              <LabelValueSection
                name={'Certificate'}
                certificateProvidersDataList={certificationProviderDataList}
                labelValuesDataList={certificateDataList}
                setLabelValuesDataList={setCertificateDataList}
                setHasChanged={setHasChanged}
              />
            </TabPanel>
          </Tabs>
        </div>
      )}
    </section>
  );
};

export default ReferenceData;
