import './ListOfSkills.scss';
import { FC, useState, useEffect, useContext } from 'react';
import Select from 'react-select';
import { ReferenceDataContext } from '../../context/ReferenceDataContext';
import { Skill as SkillType, UserSkill, SkillLevel, Department, Role } from '../../core';
import Button from '../Button/Button';
import { getReferenceDataByValue, RefDataType } from '../../utils/helpers/referenceData';
import { toast } from 'react-toastify';

interface Props {
  userDepartment: Department | undefined;
  userRole: Role | undefined;
  userSkills: UserSkill[];
  addUserSkill: (skill: UserSkill) => void;
  removeUserSkill: (skill: UserSkill) => void;
  editUserSkill: (skillToEdit: UserSkill, editedSkill: UserSkill) => void;
}

const ListOfSkills: FC<Props> = ({
  userDepartment,
  userRole,
  userSkills,
  removeUserSkill,
  addUserSkill,
  editUserSkill,
}) => {
  const [skill, setSkill] = useState<SkillType | null>();
  const [level, setLevel] = useState<SkillLevel | null>();
  const [skillToEdit, setSkillToEdit] = useState<UserSkill | null>();
  const [levelToEdit, setLevelToEdit] = useState<SkillLevel | null>();
  const [reducedSkills, setReducedSkills] = useState<SkillType[]>();

  const { skillsData, skillLevelsData, referenceData } = useContext(ReferenceDataContext);

  function enableSubmit(): boolean {
    return (
      !!skill && !!level && !!userRole && !!userDepartment && userRole?.value !== '-' && userDepartment?.value !== '-'
    );
  }

  useEffect(() => {
    const reducedArray: SkillType[] = [];

    if (skillsData) {
      skillsData.forEach((skill) => {
        let hasSkill = false;
        userSkills &&
          userSkills.forEach((userSkill) => {
            if (skill.value === userSkill.skill) {
              hasSkill = true;
            }
          });
        if (!hasSkill) {
          reducedArray.push(skill);
        }
      });

      setReducedSkills(reducedArray);
    }
  }, [skillsData, userSkills]);

  const onSubmitSkills = () => {
    if (!skill || !level) {
      (() =>
        toast.error('Failed...', {
          position: 'top-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }))();

      return;
    }

    addUserSkill({ skill: skill.value, skillLevel: level.value });

    setSkill(null);
    setLevel(null);
  };

  const confirmEditSkill = (currentSkill: UserSkill, editedSkillLevel: SkillLevel) => {
    if (!editedSkillLevel) {
      setSkillToEdit(null);
      return;
    }

    const editedSkill = currentSkill;
    editedSkill.skillLevel = editedSkillLevel.value;
    editUserSkill(currentSkill, editedSkill);
    setLevelToEdit(null);
    setSkillToEdit(null);
  };

  const removeSkill = (skillToRemove: UserSkill) => {
    setSkillToEdit(null);
    removeUserSkill(skillToRemove);
  };

  return (
    <>
      {userDepartment && userRole && Object.keys(userDepartment).length && Object.keys(userRole).length > 0 ? (
        <>
          <form className="skills-form flex justify-between gap-6">
            <div className="flex flex-col w-full">
              <label htmlFor="skill">Skill</label>
              <Select
                className="basic-single"
                classNamePrefix="select"
                id="skill"
                isSearchable={true}
                name="color"
                options={reducedSkills}
                value={skill}
                onChange={(e) => {
                  setSkill(e);
                }}
              />
            </div>
            <div className="flex w-full gap-3">
              <div className="flex flex-col w-full">
                <label htmlFor="level">Level</label>
                <Select
                  className="basic-single last"
                  classNamePrefix="select"
                  id="level"
                  isSearchable={true}
                  name="color"
                  options={skillLevelsData}
                  value={level}
                  onChange={(e) => {
                    setLevel(e);
                  }}
                />
              </div>
              <Button enabled={enableSubmit()} btnType="success" clicked={onSubmitSkills}>
                <i className="fa fa-plus" aria-hidden="true"></i>
              </Button>
            </div>
          </form>
          {userSkills.length !== 0 ? (
            <div className="skills-wrapper">
              <div className="skills">
                <table className="skills-table">
                  {userSkills.length > 0 && (
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Skill Name</th>
                        <th>Skill Level</th>
                        <th></th>
                        <th></th>
                      </tr>
                    </thead>
                  )}
                  <tbody>
                    {userSkills &&
                      userSkills.map((userSkill: UserSkill, index) => {
                        return (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{getReferenceDataByValue(userSkill.skill, RefDataType.Skill, referenceData!).label}</td>
                            <td>
                              {skillToEdit && skillToEdit.skill === userSkill.skill ? (
                                <Select
                                  className="basic-single last skill-level"
                                  classNamePrefix="select"
                                  id="level"
                                  isSearchable={true}
                                  name="color"
                                  options={skillLevelsData}
                                  value={levelToEdit}
                                  onChange={(e) => {
                                    setLevelToEdit(e);
                                  }}
                                />
                              ) : (
                                getReferenceDataByValue(userSkill.skillLevel, RefDataType.SkillLevel, referenceData!)
                                  .label
                              )}
                            </td>
                            <td>
                              {skillToEdit && skillToEdit.skill === userSkill.skill && (
                                <button
                                  className="skills-btn blue"
                                  onClick={() => confirmEditSkill(userSkill, levelToEdit!)}
                                >
                                  <i className="fas fa-check" aria-hidden="true"></i>
                                </button>
                              )}
                              {!skillToEdit && (
                                <button className="skills-btn blue" onClick={() => setSkillToEdit(userSkill)}>
                                  <i className="fa fa-edit" aria-hidden="true"></i>
                                </button>
                              )}
                            </td>
                            <td>
                              {(!skillToEdit || (skillToEdit && skillToEdit.skill === userSkill.skill)) && (
                                <button className="skills-btn delete-btn" onClick={() => removeSkill(userSkill)}>
                                  <i className="fas fa-trash-alt"></i>
                                </button>
                              )}
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </div>
          ) : (
            <></>
          )}
        </>
      ) : (
        <p className="warning">Please complete the Employee Information section</p>
      )}
    </>
  );
};

export default ListOfSkills;
