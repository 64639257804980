import { useState, useEffect, FC, useContext, useRef, Fragment } from 'react';
import randomColor from 'randomcolor';
import Modal from 'react-modal';
import { ReferenceDataContext } from '../../../context/ReferenceDataContext';
import { capitalizer } from '../../../utils/helpers/capitalizer';
import { Skill, User as UserType, SkillLevel } from '../../../core';
import Button from '../../Button/Button';
import './SkillsDistributionBarChart.scss';
import { getReferenceDataByValue, RefDataType } from '../../../utils/helpers/referenceData';

interface Props {
  listOfUsers: UserType[];
}

const modalStyles = {
  content: {
    zIndex: 5,
    top: '40%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '600px',
  },
};

const SkillsDistributionBarChart: FC<Props> = ({ listOfUsers }) => {
  const [modalIsOpen, setIsOpen] = useState(false);
  const [selectedSkill, setSelectedSkill] = useState<Skill>();
  const [usersWithSkill, setUsersWithSkill] = useState<{ userName: string; role: string; level: SkillLevel }[]>();

  const { skillsData, referenceData } = useContext(ReferenceDataContext);

  const openModal = (skill: Skill) => {
    setSelectedSkill(skill);

    const usersWithSkillArray: { userName: string; role: string; level: SkillLevel }[] = [];
    listOfUsers.forEach((user) => {
      user.userSkills &&
        user.userSkills.forEach((userSkill) => {
          if (userSkill.skill === skill.value) {
            usersWithSkillArray.push({
              userName: user.name,
              role: user.role ? getReferenceDataByValue(user.role, RefDataType.Role, referenceData!).label : '-',
              level: getReferenceDataByValue(
                userSkill.skillLevel,
                RefDataType.SkillLevel,
                referenceData!
              ) as SkillLevel,
            });
          }
        });
    });

    setUsersWithSkill(usersWithSkillArray.sort((a, b) => b.level.value - a.level.value));
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const [groupsData, setGroupsData] = useState<{ groupName: string; skillData: { skill: Skill; count: number }[] }[]>(
    []
  );
  const [maxCount, setMaxCount] = useState<number>(0);
  const barChartRef = useRef<null | HTMLDivElement>(null);

  useEffect(() => {
    const groupsDataArray: { groupName: string; skillData: { skill: Skill; count: number }[] }[] = [];

    skillsData.forEach((skill) => {
      let count = 0;
      listOfUsers &&
        listOfUsers.forEach((user) => {
          if (user.userSkills) {
            user.userSkills &&
              user.userSkills.forEach((userSkill) => {
                if (userSkill.skill === skill.value) {
                  count++;
                }
              });
          }
        });
      if (count) {
        const index = groupsDataArray.findIndex((item) => item.groupName === skill.skillGroup);
        if (index === -1) {
          groupsDataArray.push({ groupName: skill.skillGroup!, skillData: [{ skill: skill, count: count }] });
        } else {
          groupsDataArray[index].skillData.push({ skill: skill, count: count });
        }
      }
      count = 0;
    });
    setGroupsData(groupsDataArray);

    let max = 0;
    let newMax = 0;
    groupsDataArray.forEach((data) => {
      data.skillData.forEach((skillData) => {
        newMax = newMax + skillData.count;
      });
      max = newMax > max ? newMax : max;
      newMax = 0;
    });
    setMaxCount(max);
  }, [listOfUsers, skillsData]);

  const getWidth = (count: number) => {
    const maxWidth = barChartRef.current!.offsetWidth;
    const unitWidth = maxWidth / maxCount;
    return count * unitWidth;
  };

  return (
    <div className="bar-chart" ref={barChartRef}>
      {groupsData.map((groupsDatum, index) => {
        if (!groupsDatum.groupName) {
          return;
        }

        return (
          <Fragment key={index}>
            <div>{getReferenceDataByValue(groupsDatum.groupName, RefDataType.SkillGroup, referenceData!).label}</div>
            <div className="bar-row-container">
              {groupsDatum.skillData
                .sort((a, b) => b.count - a.count)
                .map((skillData, skillIndex) => {
                  return (
                    <button
                      className="bar tooltip"
                      key={skillIndex}
                      style={{
                        width: getWidth(skillData.count) + 'px',
                        backgroundColor: randomColor({ luminosity: 'dark' }),
                      }}
                      onClick={() => {
                        openModal(skillData.skill);
                      }}
                    >
                      <div className={`relative ${skillData.count < 2 ? 'text-xxsmall' : ''}`}>
                        <span>
                          {skillData.skill.label} : {skillData.count}
                        </span>
                        {skillData.skill.label} : {skillData.count}
                      </div>
                    </button>
                  );
                })}
            </div>
          </Fragment>
        );
      })}

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={modalStyles}
        contentLabel="Skill Modal"
        ariaHideApp={false}
      >
        <div className="modal-content">
          <h4>{selectedSkill?.label} - Developers</h4>
          <div className="modal-table-container">
            <table className="modal-table">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Role</th>
                  <th>Level</th>
                </tr>
              </thead>
              <tbody>
                {usersWithSkill?.map((userWithSkill, index) => {
                  return (
                    <tr key={index}>
                      <td>{userWithSkill.userName}</td>
                      <td>{userWithSkill.role}</td>
                      <td>{capitalizer(userWithSkill.level.label)}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>

          <Button btnType="amber" clicked={closeModal}>
            Close
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default SkillsDistributionBarChart;
