import { useState, FC, useRef, useContext } from 'react';
import { Department, SkillGroup, Role, Skill, CertificationProvider } from '../../../core';
import { v4 as uuidv4 } from 'uuid';
import Button from '../../../components/Button/Button';
import Select from 'react-select';
import { getReferenceDataByValue, RefDataType } from '../../../utils/helpers/referenceData';
import { ReferenceDataContext } from '../../../context/ReferenceDataContext';

interface Props {
  name: string;
  labelValuesDataList: Department[] | SkillGroup[] | Role[] | Skill[] | CertificationProvider[];
  certificateProvidersDataList?: CertificationProvider[];
  skillGroupsDataList?: SkillGroup[];
  setLabelValuesDataList: (
    labelValue: Department[] | SkillGroup[] | Role[] | Skill[] | CertificationProvider[]
  ) => void;
  setHasChanged: (boolean: boolean) => void;
}

const LabelValueSection: FC<Props> = ({
  certificateProvidersDataList,
  name,
  labelValuesDataList,
  setLabelValuesDataList,
  setHasChanged,
  skillGroupsDataList,
}) => {
  const { referenceData } = useContext(ReferenceDataContext);

  const [newLabelValueLabel, setNewLabelValueLabel] = useState<string>('');
  const [labelValueToEditLabel, setLabelValueToEditLabel] = useState<string>();
  const [labelValueToEditIndex, setLabelValueToEditIndex] = useState<number>();

  const [groupForNewCertificate, setGroupForNewCertificate] = useState<string>('');
  const [groupForNewSkill, setGroupForNewSkill] = useState<string>('');
  const [groupForEditedSkill, setGroupForEditedSkill] = useState<string>('');
  const [groupForEditedCertificate, setGroupForEditedCertificate] = useState<string>('');

  const labelValuesEndRef = useRef<null | HTMLTableSectionElement>(null);
  const isSkill = name === 'Skill';
  const isCertificate = name === 'Certificate';

  const addNewLabelValue = () => {
    if (newLabelValueLabel) {
      if (isSkill) {
        setLabelValuesDataList(
          labelValuesDataList.concat([{ label: newLabelValueLabel, value: uuidv4(), skillGroup: groupForNewSkill }])
        );
      } else if (isCertificate) {
        const newLabel = {
          label: newLabelValueLabel,
          value: uuidv4(),
          certificateProvider: groupForNewCertificate,
        } as CertificationProvider;
        labelValuesDataList === undefined
          ? setLabelValuesDataList([newLabel])
          : setLabelValuesDataList(labelValuesDataList.concat([newLabel]));
      } else {
        labelValuesDataList === undefined
          ? setLabelValuesDataList([{ label: newLabelValueLabel, value: uuidv4() }])
          : setLabelValuesDataList(labelValuesDataList.concat([{ label: newLabelValueLabel, value: uuidv4() }]));
      }
      setNewLabelValueLabel('');
      setGroupForNewSkill('');
      setGroupForNewCertificate('');
      setTimeout(() => {
        labelValuesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
      }, 200);
    }
  };

  const removeLabelValue = (index: number) => {
    const newLabelValueList = [...labelValuesDataList];
    newLabelValueList.splice(index, 1);
    setLabelValuesDataList(newLabelValueList);
  };

  return (
    <article className="mr-4">
      <form
        className="new-form"
        onSubmit={(e) => {
          e.preventDefault();
          addNewLabelValue();
        }}
      >
        <div className="input-group">
          <label htmlFor="new-labelValue">New {name}</label>
          <input
            type="text"
            id="new-labelValue"
            name="new-labelValue"
            placeholder=""
            value={newLabelValueLabel}
            maxLength={64}
            onChange={(e) => {
              setNewLabelValueLabel(e.target.value);
            }}
          />
        </div>

        {isCertificate && (
          <div className="form-group">
            <label htmlFor="groupForNewSkill">Certificate Provider</label>
            <Select
              className="basic-single"
              classNamePrefix="select"
              isSearchable={true}
              name="groupForNewCertificate"
              id="groupForNewCertificate"
              value={
                getReferenceDataByValue(
                  groupForNewCertificate as string,
                  RefDataType.CertificationProvider,
                  referenceData!
                ) as CertificationProvider
              }
              options={certificateProvidersDataList}
              onChange={(e) => {
                setGroupForNewCertificate(e?.value as string);
              }}
            />
          </div>
        )}
        {isSkill && (
          <div className="form-group">
            <label htmlFor="groupForNewSkill">Skill Group</label>
            <Select
              className="basic-single"
              classNamePrefix="select"
              isSearchable={true}
              name="groupForNewSkill"
              id="groupForNewSkill"
              value={
                getReferenceDataByValue(
                  groupForNewSkill as string,
                  RefDataType.SkillGroup,
                  referenceData!
                ) as SkillGroup
              }
              options={skillGroupsDataList}
              onChange={(e) => {
                setGroupForNewSkill(e?.value as string);
              }}
            />
          </div>
        )}
        <Button enabled={!!newLabelValueLabel} btnType="success add" clicked={addNewLabelValue}>
          <i className="fa fa-plus" aria-hidden="true"></i>
        </Button>
      </form>
      <table className="labelValues-table">
        <thead>
          <tr>
            <th className="labelValue-name whitespace-nowrap">{name} Name</th>
            {isSkill && <th className="skill-for-group-name">Skill Group</th>}
            {isCertificate && <th className="skill-for-group-name mr-5">Certificate Provider</th>}
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {labelValuesDataList &&
            labelValuesDataList.map(
              (labelValue: Department | SkillGroup | Role | Skill | CertificationProvider, index) => {
                const skillGroup = 'skillGroup' in labelValue ? labelValue['skillGroup'] : '';
                const certificateGroup = 'certificateProvider' in labelValue ? labelValue['certificateProvider'] : '';

                return (
                  <tr key={index}>
                    <td>
                      {labelValueToEditIndex !== index ? (
                        labelValue.label
                      ) : (
                        <input
                          type="text"
                          id="editing-labelValue"
                          name="edtitng-labelValue"
                          placeholder=""
                          value={labelValueToEditLabel}
                          maxLength={64}
                          onChange={(e) => {
                            setLabelValueToEditLabel(e.target.value);
                          }}
                        />
                      )}
                    </td>
                    {isSkill && (
                      <td>
                        {labelValueToEditIndex !== index ? (
                          getReferenceDataByValue(skillGroup as string, RefDataType.SkillGroup, referenceData!).label
                        ) : (
                          <Select
                            className="basic-single"
                            classNamePrefix="select"
                            isSearchable={true}
                            name="groupForNewSkill"
                            id="groupForNewSkill"
                            value={
                              getReferenceDataByValue(
                                groupForEditedSkill as string,
                                RefDataType.SkillGroup,
                                referenceData!
                              ) as SkillGroup
                            }
                            options={skillGroupsDataList}
                            onChange={(e) => {
                              setGroupForEditedSkill(e?.value as string);
                            }}
                          />
                        )}
                      </td>
                    )}
                    {isCertificate && (
                      <td>
                        {labelValueToEditIndex !== index ? (
                          getReferenceDataByValue(
                            certificateGroup as string,
                            RefDataType.CertificationProvider,
                            referenceData!
                          ).label
                        ) : (
                          <Select
                            className="basic-single"
                            classNamePrefix="select"
                            isSearchable={true}
                            name="groupForNewCertificate"
                            id="groupForNewCertificate"
                            value={
                              getReferenceDataByValue(
                                groupForEditedCertificate as string,
                                RefDataType.CertificationProvider,
                                referenceData!
                              ) as CertificationProvider
                            }
                            options={certificateProvidersDataList}
                            onChange={(e) => {
                              setGroupForEditedCertificate(e?.value as string);
                            }}
                          />
                        )}
                      </td>
                    )}
                    <td>
                      {labelValueToEditIndex !== index ? (
                        <Button
                          key={uuidv4()}
                          btnType="blue transparent"
                          clicked={() => {
                            setLabelValueToEditIndex(index);
                            setLabelValueToEditLabel(labelValue.label);
                            if (isSkill) {
                              setGroupForEditedSkill(skillGroup as string);
                            } else if (isCertificate) {
                              setGroupForEditedCertificate(certificateGroup as string);
                            }
                          }}
                        >
                          <i className="fa fa-edit"></i>
                        </Button>
                      ) : (
                        <Button
                          key={uuidv4()}
                          btnType="green transparent"
                          clicked={() => {
                            // eslint-disable-next-line
                            const reference = labelValuesDataList[index] as any;
                            if (
                              reference.label !== labelValueToEditLabel ||
                              reference.skillGroup !== groupForEditedSkill
                            ) {
                              setHasChanged(true);
                              reference.label = labelValueToEditLabel || '';
                              if (isSkill) {
                                reference.skillGroup = groupForEditedSkill;
                              } else if (isCertificate) {
                                reference.certificationProvider = groupForEditedCertificate;
                              }
                              labelValuesDataList[index] = reference;
                            }
                            setLabelValueToEditIndex(-1);
                            setLabelValueToEditLabel('');
                            setGroupForEditedSkill('');
                            setGroupForEditedCertificate('');
                          }}
                        >
                          <i className="fas fa-check"></i>
                        </Button>
                      )}
                    </td>
                    <td>
                      <button className="delete-btn" onClick={() => removeLabelValue(index)}>
                        <i className="fas fa-trash-alt"></i>
                      </button>
                    </td>
                  </tr>
                );
              }
            )}
        </tbody>
        <tfoot ref={labelValuesEndRef}></tfoot>
      </table>
    </article>
  );
};

export default LabelValueSection;
