import { FC, useContext } from 'react';
import { Route, Routes, Navigate, Outlet } from 'react-router-dom';
import { AuthContext } from './context/AuthContext';
import Header from './components/Header/Header';
import Home from './pages/Home/Home';
import Skills from './pages/Skills/Skills';
import { ToastContainer } from 'react-toastify';
import Users from './pages/Users/Users';
import Admin from './pages/Admin/Admin';
import ReferenceData from './pages/ReferenceData/ReferenceData';

type ProtectedRouteTypes = {
  isAllowed: boolean;
  redirectPath: string;
  children: React.ReactNode;
};

const ProtectedRoute = ({ isAllowed, redirectPath = '/skills', children }: ProtectedRouteTypes) => {
  if (!isAllowed) {
    return <Navigate to={redirectPath} replace />;
  }

  return children ? children : <Outlet />;
};

const App: FC = () => {
  const { user, isUserAdmin, isUserReferenceDataManager, isUserAGroupManager } = useContext(AuthContext);
  const routes = user ? (
    <Routes>
      <Route path="/" element={<Skills />} />
      <Route
        path="/users"
        element={
          // @ts-ignore
          <ProtectedRoute isAllowed={isUserAGroupManager()}>
            <Users />
          </ProtectedRoute>
        }
      />
      <Route
        path="/admin"
        element={
          // @ts-ignore
          <ProtectedRoute isAllowed={isUserAdmin()}>
            <Admin />
          </ProtectedRoute>
        }
      />
      <Route
        path="/referenceData"
        element={
          // @ts-ignore
          <ProtectedRoute isAllowed={isUserReferenceDataManager()}>
            <ReferenceData />
          </ProtectedRoute>
        }
      />
    </Routes>
  ) : (
    <Routes>
      <Route path="/" element={<Home />} />
    </Routes>
  );

  return (
    <>
      <Header />
      <ToastContainer />
      {routes}
    </>
  );
};

export default App;
