import { FC, useContext, useEffect, useState } from 'react';
import Button from '../../../components/Button/Button';
import { Department, Role, User, UserForm, UserSkill } from '../../../core';
import './UserModal.scss';
import Modal from 'react-modal';
import Select from 'react-select';
import { ReferenceDataContext } from '../../../context/ReferenceDataContext';
import ListOfSkills from '../../../components/ListOfSkills/ListOfSkills';
import { AdminContext } from '../../../context/AdminContext';
import Loader from '../../../components/Loader/Loader';
import { getReferenceDataByValue, RefDataType } from '../../../utils/helpers/referenceData';
import compareUser from '../../../utils/helpers/compareUser';

interface Props {
  modalIsOpen: boolean;
  closeModal: () => void;
  selectedUser?: User;
}

const UserModal: FC<Props> = ({ modalIsOpen, closeModal, selectedUser }) => {
  const { departmentsData, rolesData, referenceData } = useContext(ReferenceDataContext);
  const { isLoading, postUser } = useContext(AdminContext);
  const [canSubmit, setCanSubmit] = useState(false);
  const [initialUser, setInitialUser] = useState<UserForm>();
  const [name, setName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [selectedSkills, setSelectedSkills] = useState<UserSkill[]>([]);
  const [selectedDepartment, setSelectedDepartment] = useState<string>('');
  const [selectedRole, setSelectedRole] = useState<string>('');
  const [selectedUserGroups, setSelectedUserGroups] = useState<string[]>([]);

  const addUserSkill = (userSkill: UserSkill): void => {
    setSelectedSkills(selectedSkills.concat(userSkill));
  };

  const removeUserSkill = (userSkill: UserSkill): void => {
    const newArray = selectedSkills.filter((skill) => {
      return skill !== userSkill;
    });
    setSelectedSkills(newArray);
  };

  const editUserSkill = (userSkillToEdit: UserSkill, editedSkill: UserSkill): void => {
    const index = selectedSkills.indexOf(userSkillToEdit);
    const newArray = [...selectedSkills];
    newArray[index] = editedSkill;
    setSelectedSkills(newArray);
  };

  const modalStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      transform: 'translate(-50%, -50%)',
      width: '750px',
      height: '785px',
      padding: '0',
    },
  };

  useEffect(() => {
    setName((selectedUser && selectedUser.name) || '');
    setEmail((selectedUser && selectedUser.email) || '');
    setSelectedRole((selectedUser && selectedUser.role) || '');
    setSelectedDepartment((selectedUser && selectedUser.department) || '');
    setSelectedSkills((selectedUser && selectedUser.userSkills) || []);
    setSelectedUserGroups((selectedUser && selectedUser.userGroups) || []);

    setInitialUser({
      name: (selectedUser && selectedUser.name) || '',
      email: (selectedUser && selectedUser.email) || '',
      selectedRole: (selectedUser && selectedUser.role) || '',
      selectedDepartment: (selectedUser && selectedUser.department) || '',
      selectedSkills: (selectedUser && selectedUser.userSkills) || [],
      selectedUserGroups: (selectedUser && selectedUser.userGroups) || [],
    });
  }, [selectedUser]);

  useEffect(() => {
    if (name === '' || email === '' || selectedRole === '' || selectedDepartment === '') {
      setCanSubmit(false);
      return;
    }

    if (
      compareUser(initialUser as UserForm, {
        name,
        email,
        selectedRole,
        selectedDepartment,
        selectedSkills,
        selectedUserGroups,
      })
    ) {
      setCanSubmit(true);
    } else {
      setCanSubmit(false);
    }
  }, [name, email, selectedRole, selectedDepartment, selectedSkills, selectedUserGroups]);

  useEffect(() => {
    if (!isLoading) {
      closeModal();
    }
  }, [isLoading]);

  const submit = () => {
    if (
      selectedUser &&
      selectedUser.department === selectedDepartment &&
      selectedUser.email === email &&
      selectedUser.name === name &&
      selectedUser.role === selectedRole &&
      selectedUser.userSkills === selectedSkills &&
      selectedUser.userGroups === selectedUserGroups
    ) {
      closeModal();
      return;
    } else if (selectedRole && selectedDepartment) {
      postUser({
        id: selectedUser && selectedUser.id,
        name: name,
        email: email,
        department: selectedDepartment,
        role: selectedRole,
        userSkills: selectedSkills,
        username: selectedUser && selectedUser.username,
        userGroups: selectedUserGroups,
      });
    }
  };

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      style={modalStyles}
      contentLabel="Employee Modal"
      ariaHideApp={false}
    >
      <div className="modal-content user-modal-content">
        <h2 className="mb-2">{selectedUser ? 'Edit' : 'Add'} User</h2>
        <div className="user-form">
          <div className="flex justify-between">
            <div className="input-group !mr-0">
              <label htmlFor="name">Name</label>
              <input
                type="text"
                id="name"
                name="name"
                placeholder="Name"
                value={name}
                maxLength={26}
                onChange={(e) => {
                  setName(e.target.value);
                }}
              />
            </div>

            <div className="input-group !mr-0">
              <label htmlFor="email">Email</label>
              <input
                type="text"
                id="email"
                name="email"
                placeholder="Email"
                value={email}
                maxLength={26}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
            </div>
          </div>
          <div className="flex justify-between">
            <div className="form-group">
              <label htmlFor="department">Department</label>{' '}
              <Select
                className="basic-single"
                classNamePrefix="select"
                isSearchable={true}
                name="department"
                id="department"
                value={
                  getReferenceDataByValue(
                    selectedDepartment as string,
                    RefDataType.Department,
                    referenceData!
                  ) as Department
                }
                options={departmentsData}
                onChange={(e) => {
                  setSelectedDepartment(e?.value as string);
                }}
              />
            </div>
            <div className="form-group">
              <label htmlFor="role">Role</label>{' '}
              <Select
                className="basic-single sort"
                classNamePrefix="select"
                isSearchable={true}
                name="role"
                id="role"
                value={getReferenceDataByValue(selectedRole as string, RefDataType.Role, referenceData!) as Role}
                options={rolesData}
                onChange={(e) => {
                  setSelectedRole(e?.value as string);
                }}
              />
            </div>
          </div>
          <div>
            <ListOfSkills
              userDepartment={
                getReferenceDataByValue(
                  selectedDepartment as string,
                  RefDataType.Department,
                  referenceData!
                ) as Department
              }
              userRole={getReferenceDataByValue(selectedRole as string, RefDataType.Role, referenceData!) as Role}
              userSkills={selectedSkills}
              addUserSkill={addUserSkill}
              removeUserSkill={removeUserSkill}
              editUserSkill={editUserSkill}
            />
            {selectedUser && selectedUser.userSkills.length === 0 && (
              <p className="no-skills-text">This user has no skills set</p>
            )}
          </div>
        </div>
        {isLoading ? (
          <Loader />
        ) : (
          <div className="buttons-container row">
            <Button enabled={!isLoading} btnType="amber wide" clicked={closeModal}>
              Cancel
            </Button>
            <Button enabled={!isLoading && canSubmit} btnType="success wide" clicked={submit}>
              Submit
            </Button>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default UserModal;
