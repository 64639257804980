import './index.scss';
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';
import ReferenceDataContextProvider from './context/ReferenceDataContext';
import UserContextProvider from './context/UserContext';
import reportWebVitals from './reportWebVitals';
import AuthContextProvider from './context/AuthContext';
import AdminContextProvider from './context/AdminContext';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 300000, // 5 Mins
      retry: false, // Disable query retries.
    },
  },
});
const root = createRoot(document.getElementById('root')!);

root.render(
  <StrictMode>
    <QueryClientProvider client={queryClient}>
      <AuthContextProvider>
        <ReferenceDataContextProvider>
          <AdminContextProvider>
            <UserContextProvider>
              <Router>
                <App />
              </Router>
            </UserContextProvider>
          </AdminContextProvider>
        </ReferenceDataContextProvider>
      </AuthContextProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  </StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
