import { User, ReferenceData } from '../../core';
import { getReferenceDataByValue, RefDataType } from '../../utils/helpers/referenceData';

export enum SortOrder {
  Default = 'default',
  Ascend = 'ascend',
  Descend = 'descend',
}

export const sortOrderTable = (
  sortOrder: SortOrder,
  sortType: string,
  users: User[],
  referenceData: ReferenceData
): User[] => {
  switch (sortType) {
    case 'name': {
      const orderedUsers = users.sort((a, b) => a?.name?.toLowerCase().localeCompare(b?.name?.toLowerCase()));
      return sortOrder === SortOrder.Ascend
        ? orderedUsers
        : sortOrder === SortOrder.Descend
        ? orderedUsers.reverse()
        : users;
    }
    case 'email': {
      const orderedUsers = users.sort((a, b) =>
        String(a?.email)?.toLowerCase().localeCompare(String(b?.email)?.toLowerCase())
      );
      return sortOrder === SortOrder.Ascend
        ? orderedUsers
        : sortOrder === SortOrder.Descend
        ? orderedUsers.reverse()
        : users;
    }
    case 'department': {
      const orderedUsers = users.sort((a, b) =>
        getReferenceDataByValue(a?.department, RefDataType.Department, referenceData!)
          .label?.toLowerCase()
          .localeCompare(
            getReferenceDataByValue(b?.department, RefDataType.Department, referenceData!).label?.toLowerCase()
          )
      );
      return sortOrder === SortOrder.Ascend
        ? orderedUsers
        : sortOrder === SortOrder.Descend
        ? orderedUsers.reverse()
        : users;
    }
    case 'role': {
      const orderedUsers = users.sort((a, b) =>
        getReferenceDataByValue(a?.role, RefDataType.Role, referenceData!)
          .label?.toLowerCase()
          .localeCompare(getReferenceDataByValue(b?.role, RefDataType.Role, referenceData!).label?.toLowerCase())
      );
      return sortOrder === SortOrder.Ascend
        ? orderedUsers
        : sortOrder === SortOrder.Descend
        ? orderedUsers.reverse()
        : users;
    }
    default: {
      return users;
    }
  }
};
