import { FC } from 'react';
import './SkillLevelTable.scss';

const SkillLevelTable: FC = () => {
  return (
    <table className="skill-definition-table">
      <tbody>
        <tr>
          <th>Level</th>
          <th>Description</th>
        </tr>
        <tr>
          <td>Beginner</td>
          <td>
            Some basic knowledge. You could follow similar examples provided to you in order to complete tasks relating
            to this skill with a bit of support.
          </td>
        </tr>
        <tr>
          <td>Intermediate</td>
          <td>
            Good all round knowledge. You can adapt previous examples of work given to you in order to satisfy new use
            cases or scenarios; allowing you to work independently. You can help and support others with this skill in
            some areas.
          </td>
        </tr>
        <tr>
          <td>Advanced</td>
          <td>
            Good all round knowledge. You can adapt previous examples of work given to you in order to satisfy new use
            cases or scenarios; allowing you to work independently. You can help and support others with this skill in
            some areas. You can contribute to standards and practices for this skill within a team.
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default SkillLevelTable;
