import './ListForm.scss';
import { useState, useContext, useEffect, FC } from 'react';
import Select from 'react-select';
import { ReferenceDataContext } from '../../context/ReferenceDataContext';
import { UserContext } from '../../context/UserContext';
import { Department, Role } from '../../core';
import { getReferenceDataByValue, RefDataType } from '../../utils/helpers/referenceData';

const ListForm: FC = () => {
  const { userData, setUserData } = useContext(UserContext);
  const { department, role } = userData;
  const { departmentsData, rolesData, referenceData } = useContext(ReferenceDataContext);

  const [reducedRoles, setReducedRoles] = useState<Role[]>();
  const [reducedDepartments, setReducedDepartments] = useState<Department[]>();

  useEffect(() => {
    if (departmentsData && rolesData) {
      setReducedDepartments(departmentsData.filter((item) => item.label !== 'All'));
      setReducedRoles(rolesData.filter((item) => item.label !== 'All'));
    }
  }, [departmentsData, rolesData, referenceData, userData]);

  return (
    <>
      <h3>Employee Information</h3>
      <form className="employee-info-form">
        <div className="form-group">
          <label htmlFor="department">Department</label>
          <Select
            className="basic-single"
            classNamePrefix="select"
            id="department"
            isSearchable={true}
            name="color"
            options={reducedDepartments}
            value={getReferenceDataByValue(department as string, RefDataType.Department, referenceData!)}
            onChange={(e) => {
              e && setUserData({ ...userData, department: e.value as string });
            }}
          />
        </div>
        <div className="form-group">
          <label htmlFor="role">Role</label>
          <Select
            className="basic-single"
            classNamePrefix="select"
            id="role"
            isSearchable={true}
            name="color"
            options={reducedRoles}
            value={getReferenceDataByValue(role as string, RefDataType.Role, referenceData!)}
            onChange={(e) => {
              e && setUserData({ ...userData, role: e.value as string });
            }}
          />
        </div>
      </form>
    </>
  );
};

export default ListForm;
