export const capitalizer: (string?: string) => string | undefined = (string?: string) => {
  if (!string) {
    return;
  }
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
};

export const nameCapitalizer: (string?: string) => string | undefined = (string?: string) => {
  if (!string) {
    return;
  }
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
};
