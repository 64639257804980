import './Button.scss';

interface Props<T> {
  btnType?: string;
  clicked?: (param?: T) => void;
  children: React.ReactNode;
  enabled?: boolean;
}

function Button<T>({ btnType, clicked, children, enabled = true }: Props<T>) {
  const click = (e: React.MouseEvent) => {
    e.preventDefault();
    if (enabled && clicked) {
      clicked();
    }
  };

  return (
    <button type="button" className={['button', !enabled && 'disabled', [btnType]].join(' ')} onClick={(e) => click(e)}>
      {children}
    </button>
  );
}

export default Button;
