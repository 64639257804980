import { FC, createContext, useState, useEffect } from 'react';
import config from '../auth-config.json';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth/lib/types';
import { Amplify, Auth } from 'aws-amplify';
import { nameCapitalizer } from '../utils/helpers/capitalizer';

Amplify.configure(config);
interface Props {
  children: React.ReactNode;
}

interface AuthInterface {
  user: any;
  username: string | undefined;
  signIn: () => void;
  signOut: () => void;
  isUserAdmin: () => boolean;
  isUserReferenceDataManager: () => boolean;
  isUserAGroupManager: () => boolean;
  isUserSuperAdmin: () => boolean;
  checkUser: () => void;
}

export const AuthContext = createContext<AuthInterface>({} as AuthInterface);

const AuthContextProvider: FC<Props> = ({ children }) => {
  const [user, setUser] = useState<any | undefined>(() => {
    const localUser = localStorage.getItem('user');
    let local = undefined;
    if (localUser) {
      local = JSON.parse(localUser);
    }
    return local;
  });
  const [username, setUsername] = useState<string | undefined>();

  useEffect(() => {
    checkUser();
  }, []);

  useEffect(() => {
    if (user) {
      setUsername(
        nameCapitalizer(user && user.idToken && user.idToken.payload && user.idToken.payload.email.split('@')[0])
      );
    }
  }, [user]);

  const signIn = async () => {
    try {
      await Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Google });
    } catch (error) {
      localStorage.removeItem('user');
      console.log(error);
    }
  };

  const checkUser = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      setUser(user.signInUserSession);
      localStorage.setItem('user', JSON.stringify(user.signInUserSession));
    } catch (err) {
      localStorage.removeItem('user');
      console.log(err);
    }
  };

  const signOut = () => {
    Auth.signOut();
    localStorage.removeItem('user');
  };

  const isUserSuperAdmin = (): boolean => {
    if (!user) return false;
    return user.idToken.payload['cognito:groups'].includes('superadmin-group');
  };

  const isUserAdmin = (): boolean => {
    if (!user) return false;
    if (isUserSuperAdmin()) {
      return true;
    }
    return user.idToken.payload['cognito:groups'].includes('admin-group');
  };
  const isUserReferenceDataManager = (): boolean => {
    if (!user) return false;
    if (isUserSuperAdmin()) {
      return true;
    }
    return user.idToken.payload['cognito:groups'].includes('reference-data-management-group');
  };
  const isUserAGroupManager = (): boolean => {
    if (!user) return false;
    if (isUserSuperAdmin()) {
      return true;
    }
    return user.idToken.payload['cognito:groups'].includes('user-management-group');
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        username,
        signIn,
        signOut,
        isUserAdmin,
        isUserReferenceDataManager,
        isUserAGroupManager,
        isUserSuperAdmin,
        checkUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
