import { FC, useContext } from 'react';
import { AuthContext } from '../../context/AuthContext';

const Home: FC = () => {
  const { user } = useContext(AuthContext);

  return (
    <section className="wrapper">
      <h1 className="mt-10">Welcome to your skills app</h1>
      {!user && <h2>Login to view and add new skills</h2>}
    </section>
  );
};

export default Home;
