import { useContext, useState, FC } from 'react';
import Button from '../../components/Button/Button';
import Loader from '../../components/Loader/Loader';
import { AdminContext } from '../../context/AdminContext';
import { ReferenceDataContext } from '../../context/ReferenceDataContext';
import { User, UserSkill } from '../../core';
import { getReferenceDataByValue, RefDataType } from '../../utils/helpers/referenceData';
import UserModal from './UserModal/UserModal';
import DeleteModal from './DeleteModal/DeleteModal';
import './Users.scss';

const Users: FC = () => {
  const { allUsers, isLoading } = useContext(AdminContext);
  const { referenceData } = useContext(ReferenceDataContext);

  const [modalIsOpen, setIsOpen] = useState<boolean>(false);
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState<boolean>(false);
  const [selectedUser, setSelectedUser] = useState<User | undefined>();

  const renderSkills = (userSkills: UserSkill[]) => {
    let skillsString = '';
    userSkills &&
      userSkills.forEach((userSkill, index) => {
        const isLastItem = userSkills.length - 1 === index;
        skillsString =
          skillsString +
          getReferenceDataByValue(userSkill.skill as string, RefDataType.Skill, referenceData!).label +
          (isLastItem ? '' : ', ');
      });
    return skillsString;
  };

  const openModal = (user?: User) => {
    setSelectedUser(user);
    setIsOpen(true);
  };

  const openDelete = (user?: User) => {
    setSelectedUser(user);
    setDeleteModalIsOpen(true);
  };

  const closeModal = () => {
    setSelectedUser(undefined);
    setIsOpen(false);
    setDeleteModalIsOpen(false);
  };

  return (
    <section className="wrapper users-page">
      <div className="page-heading">
        <h2>Users Dashboard</h2>
      </div>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <div className="add-button-container">
            <Button btnType="success" clicked={openModal}>
              Add User
            </Button>
          </div>
          <div className="admin-users-table-container">
            <table className="admin-users-table">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Department</th>
                  <th>Role</th>
                  <th>Skills</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {allUsers &&
                  allUsers
                    .sort((a, b) => {
                      const departmentA = a.department || '';
                      const departmentB = b.department || '';
                      return departmentA.localeCompare(departmentB);
                    })
                    .map((user: User, index) => {
                      return (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{user.name}</td>
                          <td>{user.email}</td>
                          <td>
                            {user.department
                              ? getReferenceDataByValue(user.department, RefDataType.Department, referenceData!).label
                              : '-'}
                          </td>
                          <td>
                            {user.role
                              ? getReferenceDataByValue(user.role, RefDataType.Role, referenceData!).label
                              : '-'}
                          </td>
                          <td className="user-skills">{renderSkills(user.userSkills)}</td>
                          <td className="edit-delete">
                            <Button btnType="blue transparent" clicked={() => openModal(user)}>
                              <i className="fa fa-edit" aria-hidden="true"></i>
                            </Button>
                            <Button btnType="red transpaent" clicked={() => openDelete(user)}>
                              <i className="fas fa-trash-alt" aria-hidden="true"></i>
                            </Button>
                          </td>
                        </tr>
                      );
                    })}
              </tbody>
            </table>
          </div>
        </>
      )}
      <DeleteModal deleteModalIsOpen={deleteModalIsOpen} closeModal={closeModal} selectedUser={selectedUser} />
      <UserModal modalIsOpen={modalIsOpen} closeModal={closeModal} selectedUser={selectedUser} />
    </section>
  );
};

export default Users;
