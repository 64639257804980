import { FC, useContext, useEffect, useState } from 'react';
import Button from '../../../components/Button/Button';
import { User } from '../../../core';
import Modal from 'react-modal';
import { AdminContext } from '../../../context/AdminContext';
import Loader from '../../../components/Loader/Loader';
import { AuthContext } from '../../../context/AuthContext';

interface Props {
  deleteModalIsOpen: boolean;
  closeModal: () => void;
  selectedUser?: User;
}

const DeleteModal: FC<Props> = ({ deleteModalIsOpen, closeModal, selectedUser }) => {
  const { isLoading, deleteUser } = useContext(AdminContext);
  const { isUserSuperAdmin } = useContext(AuthContext);

  const [name, setName] = useState<string>('');
  const [email, setEmail] = useState<string>('');

  const modalStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: '500px',
      height: '30%',
    },
  };

  useEffect(() => {
    setName((selectedUser && selectedUser.name) || '');
    setEmail((selectedUser && selectedUser.email) || '');
  }, [selectedUser]);

  useEffect(() => {
    if (!isLoading) {
      closeModal();
    }
  }, [isLoading]);

  const submit = () => {
    if (isUserSuperAdmin()) {
      deleteUser(email);
    }
  };

  return (
    <Modal
      isOpen={deleteModalIsOpen}
      onRequestClose={closeModal}
      style={modalStyles}
      contentLabel="Employee Modal"
      ariaHideApp={false}
    >
      <div className="modal-content flex flex-col justify-around">
        <h2>Delete User</h2>
        <p className="py-8 px-0 text-center">Are you sure you want to delete {name}?</p>
        {isLoading ? (
          <Loader />
        ) : (
          <div className="flex justify-around w-[450px]">
            <Button enabled={!isLoading} btnType="amber wide" clicked={closeModal}>
              Cancel
            </Button>
            <Button enabled={!isLoading} btnType="error wide" clicked={submit}>
              Delete
            </Button>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default DeleteModal;
