import { UserForm } from '../../core';
import compareArray from './compareArray';

const compareUser = (initialUser: UserForm, newUser: UserForm) => {
  if (
    newUser.name !== initialUser!.name ||
    newUser.email !== initialUser!.email ||
    newUser.selectedRole !== initialUser!.selectedRole ||
    newUser.selectedDepartment !== initialUser!.selectedDepartment ||
    !compareArray(newUser.selectedSkills, initialUser!.selectedSkills) ||
    !compareArray(newUser.selectedUserGroups, initialUser!.selectedUserGroups)
  ) {
    return true;
  } else {
    return false;
  }
};

export default compareUser;
