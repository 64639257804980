import { FC, ReactChild } from 'react';
import { NavLink } from 'react-router-dom';
import './NavItem.scss';

interface Props {
  link: string;
  children: ReactChild;
}

const NavItem: FC<Props> = ({ link, children }) => (
  <li className="nav-item flex ml-10">
    <NavLink
      to={link}
      className={(props) => {
        return `${props.isActive ? 'nav-item active ' : 'nav-item'}`;
      }}
    >
      {children}
    </NavLink>
  </li>
);

export default NavItem;
