const compareArray = (arr1: unknown[], arr2: unknown[]) => {
  if (arr1.length !== arr2.length) return false;

  // copy arrays by value, not by reference
  const tempArr1 = arr1.slice().sort();
  const tempArr2 = arr2.slice().sort();

  for (let i = 0; i < tempArr1.length; i++) {
    if (tempArr1[i] !== tempArr2[i]) return false;
  }

  return true;
};

export default compareArray;
